(function () {
    var count = $('#mapa').length;
    if (count > 0) {
        $.getScript('//www.openlayers.org/api/OpenLayers.js', function () {
            $('#mapa').each(function (i) {
                $(this).mapContent({});
            });
        });
    }
})($);

(function ($) {
    $.fn.mapContent = function (options) {
        var st = $.extend({typo: 0, arr: [], tr: 0}, options);
        var div = $(this);

        var map;
        var mapaEl = $(this)[0];
        var titulo = $(this).data('titulo');
        var icon = $(this).data('icon');

        var lat = -29.441114;
        var lon = -51.9877123;
        var zoom = 17;

        map = new OpenLayers.Map(div[0], {
            controls: [
                new OpenLayers.Control.Navigation(),
                new OpenLayers.Control.PanZoomBar(),
                new OpenLayers.Control.ScaleLine(),
                new OpenLayers.Control.Permalink('permalink'),
                new OpenLayers.Control.MousePosition(),
                new OpenLayers.Control.Attribution()
            ],
            projection: new OpenLayers.Projection("EPSG:900913"),
            displayProjection: new OpenLayers.Projection("EPSG:4326")
        });
        console.log(lat);

        var mapnik = new OpenLayers.Layer.OSM("OpenStreetMap (Mapnik)");

        map.addLayer(mapnik);

        var lonLat = new OpenLayers.LonLat(lon, lat).transform(
                new OpenLayers.Projection("EPSG:4326"), // transform from WGS 1984
                map.getProjectionObject() // to Spherical Mercator Projection
                );

        map.addLayer(new OpenLayers.Layer.OSM());

        // Creo una variabile contenete il layer dei marker poi collego il layer dei markers alla mappa
        var LayerMarkers = new OpenLayers.Layer.Markers("Markers");
        map.addLayer(LayerMarkers);

        // Aggiungo al layer dei marker un marker (utilizzando l'oggetto lonLat per le coordinate)
        LayerMarkers.addMarker(new OpenLayers.Marker(lonLat));

        map.setCenter(lonLat, zoom);
        
        div.find('.olMapViewport').css('z-index', '-200');

    };
})(jQuery);
$(document).ready(function () {
    var show = {};
    var reserva = {evento: 0, local: 0, etapa: 0, payment: 0};
    var list_show = $('datalist.reservas').text();
    list_show = $.parseJSON(list_show);
    $('datalist.reservas').remove();

    $('.lista-agenda a').click(function () {
        reserva.evento = $(this).parent('.item').data('id');
        reserva.etapa = 1;
        setTimeout(function () {
            $.each(list_show, function (i, vo) {
                if (reserva.evento == vo.id) {
                    show = vo;
                    result_show(vo);
                }
            });

            $('.partes .cad').addClass('hidden');
            $('.partes .cad.' + reserva.etapa).removeClass('hidden');
        }, 100);
        return false;
    });
    
    $('.lista-agenda .reservar[data-ativo=1] a').trigger('click');

    $('.partes svg a').click(function () {
        var ref = $(this).attr('href');
        var texto = $(this).data('texto');
        setTimeout(function () {
            if (ref != '') {
                $('.col-result .result-spot .item').removeClass('selected');
                $('.col-result .result-spot .dados').removeClass('select').html('');
                $.each(show.reservas, function (i, vo) {
                    if (vo.referencia == ref && vo.idcliente == 0) {
                        $('.col-result .result-spot .item').addClass('selected');
                        var $result = $('.col-result .result-spot .dados').addClass('select');
                        texto += '<br/>Valor R$ '+vo.valortotal;
                        texto += '<br/>Capacidade '+vo.quantidade+' pessoas';
                        $('<h3>').appendTo($result).html(texto);
                        reserva.etapa = 2;
                        reserva.local = ref;
                        $('.list-form form [name=idreserva]').val(vo.id);

                        $('.partes .cad').addClass('hidden');
                        $('.partes .cad.' + reserva.etapa).removeClass('hidden');
                    }
                });
            }
        }, 100);
        return false;
    });


    $('.col-result .item span').click(function () {
        reserva.etapa = $(this).parents('.item').data('type');
        $('.partes .cad').addClass('hidden');
        $('.partes .cad.' + reserva.etapa).removeClass('hidden');
        $('.list-form form [name=meta]').val('edit');

        $('.col-result > div').each(function (e, i) {
            if (reserva.etapa <= e) {
                $(this).find('.item').removeClass('selected');
                $(this).find('.dados').removeClass('select');
                $(this).find('.dados').html('');
            }
        });
    });

    $('.list-form form button').on('click', function () {
        if(reserva_form($('.list-form form'))){
            $('.col-result .result-personal .item').addClass('selected');
            var $result = $('.col-result .result-personal .dados').addClass('select');
            $('.list-form form input.shadow').each(function () {
                var q = $(this).attr('placeholder');
                q += ' ' + $(this).val();
                $('<h3>').appendTo($result).html(q);
            });
            //$('<h3>').appendTo($result).html(texto);
            reserva.etapa = 3;

            $('.partes .cad').addClass('hidden');
            $('.partes .cad.' + reserva.etapa).removeClass('hidden');
        }
    });

    $('.list-pay .bt-deposito').click(function () {
        $('.list-pay .bt-pagseguro').addClass('hidden');
        $('.list-pay .dados-deposito').removeClass('hidden');
        $('.list-pay .dados-confirmar').removeClass('hidden');
        $('.list-form form [name=meta]').val('addcliente');
        reserva.etapa = 4;
        reserva.payment = 1;
        
        return false;
    });
    
    $('.list-pay .bt-pagseguro').click(function () {
        $('.list-pay .bt-deposito').addClass('hidden');
        $('.list-pay .bt-deposito').parents('.bandeira').addClass('hidden');
        $('.list-pay .dados-pagseguro').removeClass('hidden');
        $('.list-pay .dados-confirmar').removeClass('hidden');
        $('.list-form form [name=meta]').val('addcliente_pagseguro');
        reserva.etapa = 4;
        reserva.payment = 1;

        $('.col-result .result-pay .item').addClass('selected');
        //reserva_form($('.list-form form')); 
        
        return false;
    });
    
    $('.list-pay .dados-confirmar .btn-mudar').click(function () {
        $('.list-pay .bt-deposito').removeClass('hidden');
        $('.list-pay .bandeira').removeClass('hidden');
        $('.list-pay .bt-pagseguro').removeClass('hidden');
        $('.list-pay .dados-pagseguro').addClass('hidden');
        $('.list-pay .dados-confirmar').addClass('hidden');        
        $('.list-pay .dados-deposito').addClass('hidden');
    });
    $('.list-pay .dados-confirmar .btn-submit').click(function () {
        reserva_form($('.list-form form')); 
    });
});


function result_show(vo) {
    $('.col-result .result-show .item').addClass('selected');
    var $result = $('.col-result .result-show .dados').addClass('select');
    $('<img>').appendTo($result).attr({'src': vo.capa});
    var $descricao = $('<div>').appendTo($result).addClass('col-descricao');
    $('<h3>').appendTo($descricao).html(vo.titulo);
    $('<p>').appendTo($descricao).html(vo.data2);

    $('.partes .panel').addClass('fil7');
    $('.partes .panel .texto').html('reservado');
    $('.partes .panel title').html('');
    $.each(vo.reservas, function (i, panel) {
        if (panel.idcliente == 0) {
            $('.partes .panel.' + panel.referencia).removeClass('fil7');
            $('.partes .panel.' + panel.referencia).find('.texto').html('Livre - '+panel.quantidade+'p');
            $('.partes .panel.' + panel.referencia).find('title').html('R$ '+panel.valortotal);
        }
    });
}

function reserva_form($this_form) {
    var surl = $this_form.attr('action');
    var s_sucesso = ($this_form.attr('data_sucesso')) ? $this_form.attr('data_sucesso') : 'Enviado com sucesso!';
    var vo = {};

    $.each($this_form.serializeArray(), function (i, field) {
        vo[field.name] = field.value;
    });

    vo['nomeServico'] = $('#nomeServico option:selected').html();

    var ifv = true;
    $this_form.find('input,select').each(function (index) {

        var s_alert = $(this).data('vazio') ? $(this).data('vazio') : ('Campo ' + $(this).attr('placeholder') + ' obrigatório');

        if ($(this).val() == '') {
            ifv = false;
            sweetAlert("Oops...", s_alert, "warning");
            $(this).focus();
            return false;
        }
    });
    
    if(vo.meta == 'edit'){
        return ifv;
    }
    
    if (ifv) {
        var url = location.href;
        loading.create($('body'), 'Enviando formulário :)');

        $.post(surl, vo, function (resposta) {
            resposta = $.parseJSON(resposta);

            loading.remove($('body'));
            if(resposta.url != ''){
                window.open(resposta.url,'_blank');
            }

            if (resposta.resposta == 'sucesso') {
                sweetAlert("Sucesso!", resposta.msg, "success");
                location.reload();
            } else {
                sweetAlert("Oops...", resposta.msg, "error");
            }
        });
    }
    
}